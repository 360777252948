/*
 * Copyright (C) 2022 Net4Media Services SRL. - All Rights Reserved
 *
 * Soft.Eng. Florin Mihăescu <florinmihaescu@net4media.net>
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

// Typography
$font-family-sans-serif: 'Fira Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
