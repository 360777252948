/*
 * Copyright (C) 2024 Net4Media Services SRL. - All Rights Reserved
 *
 * Soft.Eng. Florin Mihăescu <florinmihaescu@net4media.net>
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
// Fonts
@import url(https://fonts.bunny.net/css?family=fira-sans:400,400i,700,700i);

// Variables
@import 'variables';

// Bootstrap
@import "~bootswatch/dist/darkly/variables";
@import 'bootstrap/scss/bootstrap';
@import "~bootswatch/dist/darkly/bootswatch";

//Fontawesome
@import 'fontawesome/scss/fontawesome';
@import 'fontawesome/scss/solid';
@import 'fontawesome/scss/regular';
